<template>
  <v-app
    id="main-background"
    :class="
      settings.graphics && settings.graphics.mode == 'low'
        ? 'low_graphics'
        : null
    "
  >
    <v-main>
      <router-view />
    </v-main>
    <DebugTool v-if="settings.debug"></DebugTool>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  components: {
    DebugTool: () =>
      import(
        /* webpackChunkName: "component-debug-tool" */ "@/components/DebugTool"
      ),
  },
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>

<style>
#main-background {
  background-color: rgba(159, 232, 243, 255);
  background: url("../assets/background/BGEntrance.png") center top;
  background-size: cover !important;
}

.low_graphics {
  background: none !important;
}
</style>