<template>
  <v-app>
    <!-- <v-system-bar height="30">
      <v-spacer></v-spacer>
     <span class="me-2">Testing : </span> 
      {{$moment().format("YYYY-MM-DD HH:mm:ss")}}
    </v-system-bar> -->
    <v-main>
      <router-view />
    </v-main>
    <DebugTool v-if="settings.debug"></DebugTool>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  components: {
    DebugTool: () =>
      import(
        /* webpackChunkName: "component-debug-tool" */ "@/components/DebugTool"
      ),
  },
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>

<style>
</style>